//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import imgdown from "@/assets/icon-down.png";
import imgdowngray from "@/assets/icon-down-gray.png";
import imgdate from "@/assets/icon-date.png";
import imgsearch from "@/assets/icon-search.png";
import imgfilter from "@/assets/icon-filter.png";

export default {
  name: "figureSignStatisticsIndex",
  data() {
    return {
      sumInfo: {
        two_years_ago_month_sales: 0,
        before_day_sales: 0,
        before_month_sales: 0,
        month_target: 0,
        year_target: 0,
      },
      minDate: new Date(2023, 6, 1),
      maxDate: new Date(),
      keywords: "",
      imgfilter,
      imgdown,
      imgdate,
      imgdowngray,
      imgsearch,
      days: 30,
      day: 1,
      currentDate: new Date(),
      currentFormatDate: this.$moment(new Date()).format("yyyy-MM"),
      showPopover: false,
      showSearch: false,
      dailyList: [],
      totalStoreCount:0,
      totalStorePersonCount:0,
      totalStorePersonSignCount:0,
    };
  },
  created() {
    this.days = this.$moment(new Date(), "YYYY-MM").daysInMonth();
    this.day = new Date().getDate() - 1;
    window.document.title = "打卡统计";
    // this.getUserInfo();
  },
  activated(){
    this.getDailyList();
  },
  methods: {
    onSearch() {
      this.getDailyList();
    },

    goDetail(item) {
      this.$router.push('/figureSignStatistics/detail?storeId=' + item.storeId +'&date=' + this.$moment(
            this.currentFormatDate +
              "-" +
              (this.day < 9 ? "0" + (this.day + 1) : this.day + 1)
          ).format("yyyy-MM-DD") );
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      }
      if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    chooseDate(e) {
      console.log(e);
      this.currentDate = e;
      this.currentFormatDate = this.$moment(e).format("yyyy-MM");
      this.days = this.$moment(e, "YYYY-MM").daysInMonth();
      this.day = 0;
      this.getDailyList();
      this.showPopover = false;
    },
    getUserInfo() {
      this.loading = true;
      const that = this;
      this.axios
        .get("/" + this.$ajaxPrefix.consumer + "/v1/wx/cp/getUserInfo")
        .then((res) => {
          console.log(res, "getUserInfo");
          that.loading = false;
          if (res && res.data && res.data.data) {
            that.roleList = res.data.data.attr.roleList;
            let saleStore = res.data.data.attr.saleStore;
            //格式化下拉列表参数
            saleStore.map((v) => {
              v.text = v.storeId + "_" + v.title;
              v.value = v.storeId;
            });
            that.storeOption = saleStore;
            that.mobile = res.data.data.mobile;
            that.username = res.data.data.username;
            that.getDailyList();
          }
        });
    },

    onClickTab(val) {
      this.day = val;
      this.getDailyList();
    },

    getDailyList() {
      const that = this;
      let postData = {
        queryMapObject: {
          storeKeyword:this.keywords,
          startTime: this.$moment(
            this.currentFormatDate +
              "-" +
              (this.day < 9 ? "0" + (this.day + 1) : this.day + 1)
          ).format("yyyy-MM-DD") + ' 00:00:00',
          endTime: this.$moment(
            this.currentFormatDate +
              "-" +
              (this.day < 9 ? "0" + (this.day + 1) : this.day + 1)
          ).format("yyyy-MM-DD") + ' 23:59:59',
        },
      };
      this.axios
        .post("/consumer/store/figuresign/getStoreFigureSignStaticList", postData)
        .then((res) => {
          console.log(res);
          if (res.data && res.data.data) {
            this.dailyList = res.data.data.list;
            this.totalStoreCount = res.data.data.totalStoreCount;
            this.totalStorePersonCount = res.data.data.totalStorePersonCount;
            this.totalStorePersonSignCount = res.data.data.totalStorePersonSignCount;
          }else{
            this.dailyList =  []
            this.totalStoreCount =  0
            this.totalStorePersonCount =  0
            this.totalStorePersonSignCount =  0
          }
        });
    },
    getNumber(val) {
      let num = Number(val);
      let str = "0";
      if (isNaN(num) || num == "NaN" || num == Infinity || num == -Infinity) {
        return "0";
      } else {
        return (
          Math.floor(num * Math.pow(10, num < 10000 ? 1 : 0)) /
          Math.pow(10, num < 10000 ? 1 : 0)
        );
      }
    },
    getRate(value, total) {
      if (
        isNaN(total) ||
        total == "NaN" ||
        total == Infinity ||
        total == -Infinity
      ) {
        return "-";
      }
      if (total == 0) {
        return "0";
      }
      let val = (parseFloat(value / total) * 100).toFixed(1);
      if (isNaN(val) || val == "NaN" || val == Infinity || val == -Infinity) {
        return "-";
      } else {
        return val + "";
      }
    },
  },
};
